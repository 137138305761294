* {
  margin: 0;
  padding: 0;
}
.title {
  text-align: center;
  font-size: 30px;

  font-family: monospace;
  /* background-color: black; */

  padding: 10px;
  font-family: monospace;
  color: rgb(40, 30, 100);
  font-weight: 800;
  /* margin: 40px; */
  margin-top: 50px;
}
.body1 {
  width: 90%;
  height: 400px;
  /* border: 3px solid red; */
  display: flex;
  margin: auto;
  background-color: rgb(40, 30, 100);
}
.bodyimg {
  width: 50%;
  /* border: 2px solid blue; */
  height: 100%;
}
.bodytext {
  width: 50%;
  /* border: 2px solid blue; */
  height: 100%;
}
img {
  width: 100%;
  height: 100%;
}
.bodytext h3 {
  margin-top: 20px;
  margin-left: 12px;
  color: white;
}
.bodytext h5 {
  margin-top: 20px;
  margin-left: 12px;
  color: white;
}
.bodytext p {
  margin-left: 10px;
  font-size: 18px;
  color: white;
  padding:0px 20px 0px 0px;
}
.buttonN {
  margin-left: 13px;
  background-color: orangered;
}
/* .button a {
  background-color: blue;
  padding: 10px 25px;
  color: white;
  font-weight: 700;
  text-decoration: none;
} */
@media only screen and (max-width: 650px) {
  .bodytext {
    font-size: 15px;
  }
}
@media only screen and (max-width: 600px) {
  .bodytext {
    font-size: 13px;
  }
  .bodytext h3 {
    font-size: 20px;
  }
  .ikkip {
    display: none;
  }
  .body1 {
    height: 400px;
  }
}
@media only screen and (max-width: 360px) {
  .bodytext {
    font-size: 10px;
  }
  .yangi {
    height: 100px;
  }
  .body1 {
    height: 300px;
  }
  .bodyimg img {
    width: 100%;
    height: 100%;
  }
}
