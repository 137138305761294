.body {
  position: relative;
  background-color: rgb(40, 30, 100);
  margin-top: 40px;
  padding-top: 15px;
  padding-bottom: 40px;
}
.bodyA {
  color: white;
  text-decoration: none;
  display: inline-block;
  font-size: 18px;
 
}
.bodyA:hover {
  color: #ff4500;
}
.bodyAIcon {
  color: white;
  margin-right:10px;
}
.bodyA:hover .bodyAIcon {
  color: #ff4500;
}
@media (max-width: 550px) {
  .bodyA {
    font-size: 25px;
  }
}
