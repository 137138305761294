.sarlavhakitob {
  font-family: monospace;
  color: rgb(67, 64, 64);
  font-weight: 800;
  /* margin: 40px; */
  margin-top: 20px;
  text-align: center;
}
.head {
  overflow-x: hidden;
  background-color: transparent;
}
.cardlar {
  box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;

}
.cardlar:hover{background-color: rgb(40, 30, 100); color:white;}
.butn{background-color: rgb(40, 30, 100);  box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset; outline: none; border:none;}
  .butn:hover{background-color: white; color:rgb(40, 30, 100);}

@media only screen and (max-width: 762px) {
  .head {
    margin-left: 40px;
  }
}
