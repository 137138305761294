.teacher {
  width: 100%;
  position: relative;
}
.teacher video {
  width: 100%;
  height: 100%;
  position: absolute;
  object-fit: cover;
  top: 0px;
  left: 0px;
  z-index: -1;
}
.blue {
  width: 100%;
  background-color: rgba(40, 30, 100, 0.885);
}
.blue img {
  width: 100%;
  height: 100% !important;
}
.blue .teachfoto {
  width: 100%;
  height: 100% !important;
  display: none;
}
.blue .teachfoto2 {
  width: 100%;
  height: 100% !important;
  display: block;
}

.blue h3 {
  margin-top: 20px;
  color: orangered;
}
.blue h5 {
  margin-top: 20px;
  color: white;
}
.blue p {
  color: white;
  margin-top: 10px;
}
.blue b {
  color: orangered;
}
@media only screen and (min-width: 200px) {
  .blue .teachfoto {
    width: 80%;
    height: 30% !important;
    margin-left: auto;
    margin-right: auto;
  }
  .blue {
    background-color: rgba(40, 30, 100);
  }
}
@media only screen and (min-width: 360px) {
  .blue .teachfoto {
    width: 80%;
    height: 33% !important;
    margin-left: auto;
    margin-right: auto;
  }
  .blue {
    background-color: rgba(40, 30, 100);
  }
}
@media only screen and (min-width: 600px) {
  .blue .teachfoto {
    width: 80%;
    height: 40% !important;
    margin-left: auto;
    margin-right: auto;
  }
  .blue {
    background-color: rgba(40, 30, 100);
  }
  @media only screen and (min-width: 992px) {
    .blue .teachfoto {
      width: 80%;
      height: 50% !important;
      margin-left: auto;
      margin-right: auto;
    }
    .blue {
      background-color: rgba(40, 30, 100);
    }
  }
  @media only screen and (min-width: 1200px) {
    .blue .teachfoto {
      display: none;
    }
    .blue .teachfoto2 {
      display: block;
    }
    .blue {
      background-color: rgba(40, 30, 100, 0.885);
    }
  }
}
@media (max-width: 991px) {
  .blue .teachfoto {
    display: block;
  }
  .blue .teachfoto2 {
    display: none;
  }
}
