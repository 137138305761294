.fio{border-right:1px solid white; color:white; padding-left:20px;}
.fio h1{font-size:22px; color:white;margin-top:50px;}
.oq{background-color:rgb(40, 30, 100); padding-top:10vh; min-height:110vh;}
.fio{height:100vh; display: flex; flex-direction: column;  justify-content:center;}
.number{height:60px; z-index: 234; transition: 0.2s; padding:15px;margin-top:50px; cursor: pointer; width:60px; font-size: 20px; color:white; border:1px solid white;display: flex; align-items:center; justify-content:center; }
.active{height:60px; padding:15px; margin-top:50px; transform: scale(1.1, 1.1); cursor: pointer; width:60px; font-size: 20px; background-color:orangered; color:white; border:1px solid white;display: flex; align-items:center; justify-content:center; }
.number:hover{transform: scale(1.1, 1.1);}
.numbers{display:flex; width:100%; overflow-x:auto; height:auto; padding-bottom:5px}
.numberT{background-color:white!important;  z-index: 234; transition: 0.2s; color: rgb(40, 30, 100);  border:1px solid rgb(40, 30, 100); height:60px;  display: flex; align-items:center; justify-content:center;padding:10px;margin-top:50px; cursor: pointer; width:60px; font-size: 20px; }
.numberT:hover{transform: scale(1.1, 1.1);}

.tt{margin-top:10vh; padding:0px; color:white;}
.tt h5{color:white;}
.test{position: relative;}
.page{color:orangered; font-size: 20px; text-align: center; margin-top: 50px; width:100%;}
.tt h4{color:white; font-size: 23px; text-align: right; margin:0px 0px 20px 0px;padding-right:30px; border-bottom: 1px solid orangered; padding-bottom: 20px; }
.butN{margin-top: 30px; padding:10px 40px;  position: absolute; right:50px;  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px; background-color: orangered; color:white; font-size: 20px; }
.butN:hover{background-color: rgb(40, 30, 100); }
.tt{position: relative; width:100%; }
.tt h6{color:white; font-size:22px; position: absolute; left:30px; top:5px;}
.send{margin-top: 30vh;}
.send h4{color:white; text-align: center;}
.send h5{color:white; text-align: center;}