.sarlavha {
  font-family: monospace;
  color: rgb(67, 64, 64);
  font-weight: 800;
  /* margin: 40px; */
  margin-top: 50px;
  text-align: center;
}
.head {
  border-radius: 10px;
  margin: 50px;
  margin-top: 20px;
  background-color: white;
  padding: 10px;
  
}
.img{padding:20px}
.you {
  width: 100%;
  height: 300px;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 14px 28px,
    rgba(0, 0, 0, 0.22) 0px 10px 10px;
}

