body{
    position: relative;
    overflow-x: hidden;
}
.top{
background-color:#FAFBFC;
height: 70px;
overflow: hidden;
z-index: 1;
z-index: 1000;
box-shadow: 0 0.46875rem 2.1875rem rgb(4 9 20 / 3%), 0 0.9375rem 1.40625rem rgb(4 9 20 / 3%), 0 0.25rem 0.53125rem rgb(4 9 20 / 5%), 0 0.125rem 0.1875rem rgb(4 9 20 / 3%);
}
.top h1{
    font-family: 'Lobster',cursive;
    margin-top: 15px;
    font-size: 30px;
    color:black;
    font-weight:500;
}
.top input{
    width: 80%;
    padding:5px 10px;
    border:2px solid #1A86D0;
    font-size: 16px;
    color:rgba(0,0,0,0.4);
    border-radius: 15px 0 0 15px;
    margin-top: 14px;
    margin-left: 60px;
}
.top button{
    border: 2px solid #1A86D0;
    background-color:#1A86D0;
    color:white;
    padding:5px;
    font-size: 16px;
    border-radius: 0 15px 15px 0;
}
.language{
text-align: right;
padding:20px;
}
.language span{
    margin: 10px;
    font-size: 18px;
    color:black;
    cursor: pointer;
}
.language img{
    width:40px;
    height: 40px;
    border-radius: 50%;
    object-fit: cover;
    margin-left: 30px;
    margin-right: 10px;
    cursor: pointer;
}
.heading{
    display: none;
}
.divOpen{
    position: absolute;
    top:140%;
    left: 50%;
    background-color:black;
    z-index: 111111111 !important;
}
@media screen and (max-width:566px) {
        .heading{
            display: block;
            margin-left: 30px;
            font-size: 20px;
        }
        .search{

          display: none;
         }
        .language{
        padding:0px;
        display:flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        margin-left: -45px;
        }
        .language span{
            font-size: 16px;
        }
}
@media screen and (min-width:567px) {
 .heading{
     display: block;
     margin-left:20px
 }
  .search{
      display: none;
  }
    .language{
    padding:10px;
    display:flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-left: -10px;
    }
    .language span{
        font-size: 16px;
    }
}
@media screen and (min-width:768px) {
    .top h1{
        margin-left: 0px;
    }
  .search{
      display: block
  }
  .heading{
    display: none;
}
  .top input{
    width: 70%;
    margin-left: 0px;
    padding:3px 10px;
    margin-top: 20px;
}
.top button{
    padding:3px 5px;
}
    .language{
    padding:10px;
    display:flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-left: -100px;
    }
}
@media screen and (min-width:992px) {
    .top h1{
        margin-left: 0px;
    }
  .search{
      display:block
  }
  .top input{
      width: 80%;
  }
    .language{
    padding:10px;
    display:flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-left: -60px;
    }
}
@media screen and (min-width:1200px) {
    .top h1{
        margin-left: 0px;
    }
  .search{
      display: block;
      margin-left:20px
  }
    .language{
    padding:10px;
    display:flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-left: -30px;
    }
    .top input{
        width: 80%;
        margin-left:20px
    }
}
