.sarlavha{color:orangered; margin-top:40px; padding:10px; text-align: center; margin-bottom:20px}
.sarlavha a{color:orangered; text-decoration:none;}
.sarlavha:hover a{color:white;}
.chiziq{width:200px; height:8px; background-color: rgb(40, 30, 100); margin-top:-20px;margin-bottom:20px; animation-name: chiziq; animation-duration:3s; animation-iteration-count: infinite;}
.chiziqoq{width:200px; height:8px; background-color: white; margin-top:-20px;margin-bottom:20px; animation-name: chiziq; animation-duration:3s; animation-iteration-count: infinite;}
@keyframes chiziq{
  0%{margin-left:0px;}
  50%{margin-left:calc(100% - 200px);}
  100%{margin-left:0px;}
} 
.navbar:hover{color:orangered}
.navbar{
  color: white;
                    margin-left: 28px;
                    font-size:20px;
                    text-decoration:none;
                    font-weight: 400;
                  
}

::-webkit-scrollbar-track
{
	box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
	background-color: rgb(40, 30, 100);
	border-radius: 10px;
  
}

::-webkit-scrollbar
{
	width: 8px;
	height: 8px;

	background-color: rgb(40, 30, 100);
}

::-webkit-scrollbar-thumb
{
	border-radius: 10px;
   background: orangered;
  
}
.formInput{font-size:14px; border-radius: 3px; padding:5px 10px;}
.formInput::placeholder {
	color: #bfbfbf;
	opacity: 0.9;
  }
.btnFormIn{position: fixed; bottom:20vh; right:0px;}
.formnew{padding:20px; background-color: white;box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;}
.tablenew{margin-top:30px;padding:20px; background-color: white;box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;}
.tablenew td{font-size:16px}
.you_col{padding:10px; background-color: white;box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;}
.mur_you{padding:10px; background-color: white;box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset; display:flex; align-items: center; justify-content:space-around}
.you_btn{display:flex; width:100%; justify-content: space-between;}
.mur_you h4{ position: relative; top:5px;}
